import { Routes, Route } from "react-router-dom";
import AccountInfo from "./components/main";
import CashOut from "./components/main/CashOut";
import Search from "./components/Search";

// function App() {
//     return (
//         <div className="flex justify-center items-center h-full bg-gray-700">
//             <div className="text-4xl text-white">Carte inconnue</div>
//         </div>
//     );
// }



function App() {
    return (
        <>
            <Routes>
                <Route path="/" element={<Search />} />
                <Route path="/:identifier/" element={<AccountInfo />} />
                <Route path="/:identifier/recharged" element={<AccountInfo />} />
                <Route path="/:identifier/cashout" element={<CashOut />} />
            </Routes>
            {process.env.REACT_APP_ENV && <div className="fixed top-0 -right-0 w-40 h-40 rotate-45">
                <div className="bg-blue-900 text-white text-center text-xs pt-[2px] pb-[3px] opacity-80 font-medium">{process.env.REACT_APP_ENV}</div>
            </div>}
        </>
    );
}

export function getDomain(addCharacterIfDomainDisplayed = '') {
    if (isNantes()) {
        return '';
    }
    return process.env.REACT_APP_DOMAIN + addCharacterIfDomainDisplayed;
}

export function getIdentifierLength() {
    if (isNantes()) {
        return 12;
    }

    return process.env.REACT_APP_SIZE;
}

export function isNantes() {
    return window.location.hostname === 'nantes.dgqr.fr' || window.location.hostname === 'nantes.sandbox.dgqr.fr';
}

export default App;
