import axios from "axios";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { getDomain, isNantes } from "../../App";
import generateUUID from "../../tools/uuid";
import Card from "../Card";
import Currency from "../Currency";
import Powered from "../Powered";
import Spinner from "../Spinner";
import { CashOutInput } from "./CashOut";
import Loading from "./Loading";
import UnknownCard from "./UnknownCard";


const API = process.env.REACT_APP_API_HOST;

export default function AccountInfo() {
    const [order, setOrder] = useState({});
    const [style, setStyle] = useState("");
    const [state, setState] = useState('start');
    const [ignoreSecurity, setIgnoreSecurity] = useState(false);
    const [transitioning, setTransitioning] = useState(false);
    const params = useParams();
    const identifier = params.identifier;


    const { status, data, error} = useQuery(['account', identifier], async () => {
        const response = await axios({
            url: API + "/refill/" + getDomain('/') + identifier,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
        });
        return response;
    }, {
        retry: false,
    });


    useEffect(() => {
        if (data && data.data) {
            const styles = Object.keys(data.data.data.settings.webapp.css).reduce((acc, key) => acc + `${key}: ${data.data.data.settings.webapp.css[key]}; `, "");
            setStyle(`:root { ${styles} }`);
        }

        if (data?.data?.data?.anonymous === false) {
            setIgnoreSecurity(true);
        }

    }, [data]);



    const transition = (to) => {
        if (to === 'start') {
            setOrder({});
        }
        setTransitioning(true);
        setTimeout(() => {
            setState(to);
            setTransitioning(false);
        }, 300);
    };

    const onSelectamount = (amount) => {
        setOrder({ ...order, amount: amount });
        transition('payment');
    }

    const onSelectGateway = (gateway) => {
        setOrder({ ...order, gateway: gateway });
        transition('process');
    }

    const goToPayment = (data) => {
        const order = data.data.data;

        window.document.location = API + '/orders/' + order.order_id + '/flow';
    }

    if (status === 'loading') {
        return <Loading />;
    }

    console.log("Error is", error);
    if (status === 'error' && error?.request?.status === 403 && error?.response?.data?.url) {
        console.log("Redirecting to ", error.response.data.url);
        window.document.location = error.response.data.url;
    }

    if (status === 'error') {
        return <UnknownCard />;
    }

    return <>
        <style>{style}</style>
        {(!ignoreSecurity && !isNantes()) && <SecureYourAccount accountId={data?.data?.data?.account_id} onIgnore={() => setIgnoreSecurity(true)} onValidate={(a, b) => setIgnoreSecurity(true)} />}
        <div className="bg-[color:var(--background-color)] transition-colors flex flex-col md:flex-row md:justify-center items-center h-full font-roboto text-[#333] overflow-auto gap-10 md:gap-0">
            <div className="w-full md:w-2/5 bg-[color:var(--primary-color)] text-[color:var(--primary-contrast)] md:h-full flex flex-col p-2 md:p-10 gap-10 md:gap-0">
                <div className="h-[50px] md:h-1/4 relative">
                    <div className="absolute w-full">
                        {data.data.data?.logo && <img alt="Logo" src={data.data.data.logo} className="max-h-[50px] md:max-h-[100px] mx-auto md:mx-0" />}
                    </div>
                </div>
                <div className="md:h-1/2 flex flex-col gap-2 md:gap-5 justify-center">
                    <div className="text-center md:text-left font-medium text-2xl md:text-5xl">Bienvenue !</div>
                    <div className="text-center md:text-left text-sm md:text-xl px-5 md:px-0">Cette page vous permet de gérer votre compte cashless en ligne</div>
                </div>
                <div className="md:h-1/4 flex items-end justify-end md:justify-start">
                    <Powered />
                </div>
            </div>
            <div className="w-full md:w-3/5 text-center relative  px-5 md:px-0 md:h-full flex flex-col md:items-center md:justify-center">
                <div className="md:absolute bottom-0 w-full pb-4 md:pb-0">
                    {data.data.data.settings.refill.cashout && <Link to={`/${identifier}/cashout`}><div className="py-2 md:py-4 bg-[color:var(--button-cta-color)] text-[color:var(--button-cta-contrast)] cursor-pointer transition-[padding] font-medium" onClick={() => transition('cashout')}>Solder son compte</div></Link>}
                </div>
                <div className="w-full md:w-11/12 max-w-xl relative mx-auto group">
                    <div className="z-10 relative">
                        <Card>
                            {!order.amount && <>
                                <div className="text-5xl">
                                    <Currency value={(data.data.data.balance) / 100} />
                                </div>
                                <div>
                                    <small>actuellement sur votre carte Cashless</small>
                                </div>
                            </>}
                            <div className="flex gap-2 justify-center items-center font-medium transition-all">
                                {order.amount && <div className="py-2">Recharge <Currency value={order.amount / 100} /></div>}
                                {order.gateway && <div>par {order.gateway.title}</div>}
                            </div>
                        </Card>
                    </div>
                    <div className="px-0 absolute text-center left-0 right-0">
                        <div className={`bg-[color:var(--primary-color)] text-[color:var(--primary-contrast)] rounded-b overflow-hidden shadow ${state === 'start' ? 'md:mt-[-6px]' : ''} group-hover:mt-0 transition-[margin,transform] cursor-pointer ease-in-out duration-300 origin-top ${transitioning ? '[transform:perspective(400px)_rotateX(-90deg)]' : '[transform:perspective(400px)_rotateX(0deg)]'}`}>
                            {state === 'start' &&
                                <>
                                    {data.data.data.settings.refill.recharge && <div className="py-2" onClick={() => transition('amount')}>Ajouter des fonds</div>}
                                </>
                            }
                            {state === 'amount' && <Pick onSuccess={onSelectamount} onCancel={() => transition('start')} />}
                            {state === 'payment' && <PaymentMethod onSuccess={onSelectGateway} data={data.data} onCancel={() => transition('start')} />}
                            {state === 'process' && <Process identifier={identifier} order={order} onSuccess={goToPayment} account={data.data.data} onCancel={() => transition('start')} />}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </>;
}

const values = [1000, 2000, 5000, 10000];


function SecureYourAccount({ onIgnore, onValidate, accountId }) {

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({});


    const { mutate } = useMutation(() => {
        return axios({
            method: 'POST',
            url: API + "/refill/secure",
            data: {
                account_id: accountId,
                name: name,
                phone: phone,
                email: email,
            },
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
        });
    });

    const onSubmitForm = (e) => {
        e.preventDefault();

        let foundErrors = {};

        if (phone.replace(/\s+/g, '').length < 8) {
            foundErrors.phone = true;
        }
        if (!email.match(/^[\w-.\-+]+@([\w-]+\.)+[\w-]{2,10}$/g)) {
            foundErrors.email = true;
        }

        setErrors(foundErrors);

        if (Object.keys(foundErrors).length > 0) {
            return;
        }

        mutate();
        onValidate();
    }

    return <div className="fixed inset-0 bg-black/80 z-50 flex items-center justify-center px-2">
        <div className="w-full md:w-[60%] max-w-full border-0 overflow-hidden border-[color:var(--primary-color)] bg-white text-black rounded-[var(--card-radius)] shadow-xl flex flex-col">
            <div className="bg-[color:var(--primary-color)] text-[color:var(--primary-contrast)] px-4 py-2">
                <h1 className="font-medium text-xl">Securisez votre compte Cashless</h1>
                <p className="text-sm">Afin d'eviter qu'une tierce personne solde votre compte, veuillez nous fournir les informations suivantes:</p>
            </div>
            <form onSubmit={onSubmitForm}>
                <div className="px-4 py-2">
                    <CashOutInput autocomplete="section-secure name" placeholder="Martin" value={name} label="Votre nom et/ou prénom" onChange={(e) => setName(e.target.value)} />
                    <CashOutInput autocomplete="section-secure phone" error={errors?.phone ? 'Numéro incorrect' : ''} placeholder="06 12 34 56 78" type="tel" value={phone} label="Votre numéro de téléphone" onChange={(e) => setPhone(e.target.value)} />
                    <CashOutInput autocomplete="section-secure email" error={errors?.email ? 'Email incorrect' : ''} placeholder="martin@exemple.com" type="email" value={email} label="Votre adresse email" onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="flex justify-between items-center px-4 py-2">
                    <button className="border border-[color:var(--primary-color)] text-[color:var(--primary-color)] rounded px-4 py-2" onClick={onIgnore}>Ignorer</button>

                    <button type="submit" className="bg-[color:var(--primary-color)] text-[color:var(--primary-contrast)] rounded px-4 py-2" onClick={onSubmitForm}>Valider ces informations</button>
                </div>
            </form>
        </div>
    </div>;
}

function Pick({ onCancel, onSuccess }) {
    return <div className="flex flex-col divide-y">
        {values.map(v => (
            <div onClick={() => onSuccess(v)} key={v} className="transition-all py-3 hover:opacity-90"><div className="inline-block text-white">+</div> <Currency value={v / 100} /></div>
        ))}
        <div className="transition-all py-3 hover:opacity-90 bg-white/50 text-black font-bold" onClick={() => onCancel()}>Annuler</div>
    </div>
}


function PaymentMethod({ data, onSuccess, onCancel }) {
    let gateways = [];
    Object.keys(data.data.settings.payment_gateways).forEach(key => {
        if (data.data.settings.payment_gateways[key].enabled === '1') {
            gateways.push({ ...data.data.settings.payment_gateways[key], provider: key });
        }
    });

    return <div className="flex flex-col divide-y">
        {gateways.map((g, i) => (
            <div onClick={() => onSuccess(g)} key={`${g.provider}_${i}`} className="transition-all py-3 hover:opacity-90">{g.title}</div>
        ))}
        <div className="transition-all py-3 hover:opacity-90 bg-white/50 text-black font-bold" onClick={() => onCancel()}>Annuler</div>
    </div>;
}

function Process({ order, account, onSuccess, identifier }) {
    const { mutate, isLoading, isSuccess, data } = useMutation(() => {
        return axios({
            method: 'POST',
            url: API + "/refill/",
            data: {
                amount: order.amount,
                account_id: account.account_id,
                provider: order.gateway.provider,
                local_id: 'refill|' + generateUUID() + '|' + identifier,
            },
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
        });
    });

    useEffect(() => {
        mutate();
    }, [mutate]);


    useEffect(() => {
        if (isSuccess && data) {
            onSuccess(data);
        }
    }, [isSuccess, data, onSuccess]);


    return <div className="flex justify-center p-5">
        {isLoading && <Spinner />}
        <div>
            {isLoading ? 'Création de la commande...' : ''}
            {isSuccess ? 'Commande créée. Redirection vers le paiement...' : ''}
        </div>
    </div>;
}