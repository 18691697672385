import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDomain, getIdentifierLength } from "../App";
import Powered from "./Powered";
import Spinner from "./Spinner";

export default function Search() {
    const [lookup, setLookup] = useState('');
    let navigate = useNavigate();

    const onChange = (a) => {
        const value = a.target.value.replace(/\s+/g, '');;

        if (value.length !== parseInt(getIdentifierLength())) {
            return;
        }

        setLookup(value);
        setTimeout(() => {
            navigate("/" + value);
        }, 1000);
    };
    return <div className="bg-gray-700 text-white font-medium h-full items-center flex flex-col justify-center text-xl lg:text-4xl relative gap-8 lg:gap-16 px-2">
        <div className="font-normal text-center">Renseignez votre identifiant pour accéder au service de recharge en ligne</div>
        <div className="flex justify-center items-center">
            <div>
                {getDomain('/')}
            </div>
            <div className={`flex items-center transition-[margin] ${lookup ? 'ml-0' : 'ml-2'} w-40 lg:w-80`}>
                {!lookup && <input onChange={onChange} placeholder="identifiant" className="appearance-none border-white h-10 lg:h-20 px-2 outline-none bg-white/10 rounded lg:rounded-xl focus:bg-white/20 w-full" />}
                {lookup && <div className="font-normal">{lookup}</div>}
                {lookup && <div className="ml-2"><Spinner /></div>}
            </div>
        </div>
        <div className="absolute bottom-1 left-2 text-xs"><Powered /></div>
    </div>;
}