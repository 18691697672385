import axios from "axios";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getDomain } from "../../App";
import Spinner from "../Spinner";
import Loading from "./Loading";
import UnknownCard from "./UnknownCard";

const API = process.env.REACT_APP_API_HOST;




/*
 * Returns 1 if the IBAN is valid 
 * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
 * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
 */
function isValidIBANNumber(input) {
    var CODE_LENGTHS = {
        AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
        CH: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
        FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
        HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
        LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
        MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
        RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,
        AL: 28, BY: 28, CR: 22, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25,
        SV: 28, TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
    };
    var iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
        code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
        digits;
    // check syntax and length
    if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
        return false;
    }
    // rearrange country code and check digits, and convert chars to ints
    digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
        return letter.charCodeAt(0) - 55;
    });
    // final check
    return mod97(digits);
}

function mod97(string) {
    var checksum = string.slice(0, 2), fragment;
    for (var offset = 2; offset < string.length; offset += 7) {
        fragment = String(checksum) + string.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
}


export default function CashOut() {
    const params = useParams();
    const navigate = useNavigate();
    const [style, setStyle] = useState("");

    const [name, setName] = useState("");
    const [iban, setIban] = useState("");
    const [phone, setPhone] = useState("");
    const [bic, setBic] = useState("");

    const [error, setError] = useState({});

    const identifier = params.identifier;

    const query = useQuery(['account', identifier], async () => {
        const response = await axios({
            url: API + "/refill/" + getDomain('/') + identifier,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
        });
        return response;
    }, {
        retry: false,
    });


    const { mutate, isLoading, isSuccess, data } = useMutation(() => {
        return axios({
            method: 'POST',
            url: API + "/refill/cashout",
            data: {
                iban: iban,
                bic: bic,
                phone: phone,
                name: name,
                account_id: query?.data?.data?.data?.account_id,
            },
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
        });
    });


    // Do not allow cashout if not enabled
    useEffect(() => {
        if (query?.data?.data?.data?.settings?.refill?.cashout === false) {
            navigate(`/${identifier}`);
        }
    }, [query?.data, identifier, navigate]);

    // Set the style if data is available
    useEffect(() => {
        if (query?.data?.data?.data?.settings?.webapp?.css) {
            const styles = Object.keys(query?.data.data.data.settings.webapp.css).reduce((acc, key) => acc + `${key}: ${query?.data.data.data.settings.webapp.css[key]}; `, "");
            setStyle(`:root { ${styles} }`);
        }
    }, [query?.data]);

    if (query?.status === 'loading') {
        return <Loading />;
    }

    if (query?.status === 'error') {
        return <UnknownCard />;
    }

    const onSubmitForm = (e) => {
        e.preventDefault();
        setError({});

        const isIbanValid = isValidIBANNumber(iban);
        if (isIbanValid !== 1) {
            setError({ iban: true });

            return;
        }

        mutate();
    };

    return <>
        <style>{style}</style>
        <div className="pt-6 md:pt-0 w-full min-h-full flex flex-col md:justify-center md:items-center px-2 bg-[color:var(--background-color)] pb-10">
            <div className="w-full md:w-[60%] max-w-full border-0 overflow-hidden border-[color:var(--primary-color)] bg-white text-black rounded-[var(--card-radius)] shadow-xl flex flex-col gap-6">
                <div className="bg-[color:var(--primary-color)] text-[color:var(--primary-contrast)] px-4 py-2">
                    <h1 className="font-medium text-xl">Solder son compte</h1>
                    <p className="text-sm">Afin de solder votre compte, merci de remplir ces informations.</p>
                </div>

                <form className="flex flex-col gap-6 px-4 pb-2" onSubmit={onSubmitForm}>
                    <div className="flex flex-col gap-4">
                        <CashOutInput className="max-w-md" label="Nom et prénom" value={name} onChange={(e) => setName(e.target.value)} />
                        <CashOutInput className="max-w-md" label="Numéro de téléphone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                        <CashOutInput className="max-w-md" label="IBAN de votre compte bancaire" value={iban} onChange={(e) => setIban(e.target.value)} error={error?.iban ? 'IBAN incorrect' : null} />
                        <CashOutInput className="max-w-md" label="BIC de votre compte bancaire" value={bic} onChange={(e) => setBic(e.target.value)} />
                    </div>

                    {isSuccess && <CashoutResponse response={data.data.data} />}

                    {(isSuccess || !data?.data?.data?.success) && <button disabled={isLoading} type="submit" className="py-2 bg-[color:var(--primary-color)] text-[color:var(--button-cta-contrast)] font-medium rounded flex items-center justify-center">
                        {(isLoading) ? <Spinner /> : 'Solder mon compte'}
                    </button>}
                    
                </form>

            </div>
            <div>
                <Link to={`/${identifier}`}>
                    <div className="py-2 text-center text-sm">Retourner vers mon compte</div>
                </Link>
            </div>
        </div>
    </>;
}

function CashoutResponse({ response }) {
    if (response.success) {
        return <div className="bg-green-400 text-black text-center rounded shadow px-2 py-4">Demande effectuée ✓</div>;
    }

    const showReason = (reason) => { 
        switch (reason) {
            case "account_not_found":
                return "Compte introuvable.";
            case "cashout_in_progress":
                return "Une procedure est déjà en cours.";
            case "phone_mismatch":
                return "Le numéro de téléphone est incorrect.";
            case "account_empty":
                return "Le compte est vide.";
            default:
                return "Erreur systeme.";
        }
    };

    return <div className="bg-red-300 font-medium text-black text-center rounded shadow px-2 py-4">{showReason(response.reason)}</div>;
}

export function CashOutInput({ label, className, value, onChange, error, type = 'text', placeholder = '', autocomplete = '' }) {
    return <div className="flex flex-col gap-1">
        <label className="text-base font-medium">{label}</label>
        <div>
            <input autocomplete={autocomplete} placeholder={placeholder} value={value} required={true} className={`${error ? 'border-red-500 text-red-500' : ''} ${className} placeholder:text-[#ccc] w-full appearance-none shadow rounded border py-2 px-4 bg-white/80 text-black`} type={type} onChange={onChange} />
            <div className="text-xs text-red-500">{error}&nbsp;</div>
        </div>
    </div>;
}